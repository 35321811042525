<template>
	<div class="page">
		<div class="xz-hand-pc" v-if="equipment == 'p'">
			<img src="../assets/img/logo1.png" class="xz-hand-logo">
		</div>
		<div class="bg" v-if="equipment == 'p'">
			<div class="xz-bt">榕树万千  -  客户端下载</div>
			<div class="xz-nr">点击下载客户端</div>
			<div class="xz-list-bg">
				<div class="xz-list">
					<img src="../assets/img/win.png" class="xz-img">
					<div class="xz-ms">适用于Windows10和Windows11系统</div>
					<a :href="'https://www.dmclkj.com/'+wurl" :download="wname">
						<el-button size="medium" round icon="el-icon-bottom" class="xz-an">下载客户端</el-button>
					</a>
				</div>
<!-- 				<div class="xz-list">
					<img src="../assets/img/and.png" class="xz-img">
					<div class="xz-ms">适用于Android7.0以上系统</div>
					<a :href="'https://www.dmclkj.com/'+aurl" :download="aname">
						<el-button size="medium" round icon="el-icon-bottom" class="xz-an">下载客户端</el-button>
					</a>
				</div>
				<div class="xz-list xz-list1">
					<img src="../assets/img/mac.png" class="xz-img">
					<div class="xz-ms">请在苹果商城搜索榕树万千下载</div>
					<img src="../assets/img/store.png" class="store">
				</div> -->
			</div>
			<div class="foot">
				<a href="https://beian.miit.gov.cn/" target="_blank">滇ICP备2021002230号-1</a><br>
				北京独木成林科技有限公司<br>
				Beijing One Tree Forest Technology Co., Ltd.
			</div>
		</div>
		
		<div class="hade" v-if="equipment == 'y'">
			<img src="../assets/img/logo1.png" class="logo"/>
		</div>
		<div class="bg-y" v-if="equipment == 'y'">
			<div class="xz-bt-y">榕树万千  -  客户端下载</div>
			<div class="xz-nr-y">点击下载客户端</div>
			<div class="xz-list-bg-y">
				<div class="xz-list-y">
					<img src="../assets/img/win.png" class="xz-img-y">
					<div class="xz-ms-y">适用于Windows10和11系统</div>
					<a :href="'https://www.dmclkj.com/'+wurl" :download="wname" v-if="wx==''">
						<div class="xz-y">下载客户端</div>
					</a>
					<div class="xz-y" v-if="wx!=''" @click="ts=true">下载客户端</div>
				</div>
<!-- 				<div class="xz-list-y">
					<img src="../assets/img/and.png" class="xz-img-y">
					<div class="xz-ms-y">适用于Android7.0以上系统</div>
					<a :href="'https://www.dmclkj.com/'+aurl" :download="aname" v-if="wx==''">
						<div class="xz-y">下载客户端</div>
					</a>
					<div class="xz-y" v-if="wx!=''" @click="ts=true">下载客户端</div>
				</div>
				<div class="xz-list-y">
					<img src="../assets/img/mac.png" class="xz-img-y">
					<div class="xz-ms-y">请在苹果商城搜索榕树万千下载</div>
					<img src="../assets/img/store.png" class="store-y">
				</div> -->
			</div>
			<div class="foot-y">
				<a href="https://beian.miit.gov.cn/" target="_blank">滇ICP备2021002230号-1</a><br>
				北京独木成林科技有限公司<br>
				Beijing One Tree Forest Technology Co., Ltd.
			</div>
		</div>
		<div class="wx-xz-ts-bg" v-if="ts"  @click="ts=false">
			<img src="../assets/img/ysjts.png" class="wx-xz-ts-img">
		</div>
	</div>
</template>

<style scoped>
.wx-xz-ts-img{
	display: block;
	width: 90%;
	margin: 0 auto;
	margin-top: 10px;
}
.wx-xz-ts-bg{
	width: 100%;
	height: 100VH;
	position: fixed;
	left: 0;
	top: 0;
	background: rgb(0,0,0,0.75);
}
.foot-y{
	width: 100%;
	margin-top: 50px;
	color: #68747f;
	line-height: 30px;
	text-align: center;
	font-size: 18px;
	padding-top: 20px;
	padding-bottom: 20px;
}
.foot-y a{
	color: #68747f!important;
}
.store-y{
	display: block;
	height: 58px;
	margin: 0 auto;
	margin-top: 30px;
}
.xz-y{
	width: 160px;
	height: 58px;
	border: 1px solid #dcdfe6;
	background: #FFFFff;
	text-align: center;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 29px;
	margin: 0 auto;
	color: #606266;
	font-size: 22px;
	margin-top: 30px;
}
.xz-ms-y{
	width: 100%;
	color: #999999;
	text-align: center;
	font-size: 26px;
	margin-top: 20px;
}
.xz-img-y{
	height: 130px;
	display: block;
	margin: 0 auto;
}
.xz-list-y{
	background: #f5f9fa;
	width: 100%;
	padding-left: 23px;
	padding-right: 23px;
	padding-top: 50px;
	padding-bottom: 50px;
	box-sizing: border-box;
	margin-bottom: 30px;
}
.xz-list-bg-y{
	width: calc(100% - 46px);
	margin: 0 auto;
}
.xz-bt-y{
	width: calc(100% - 46px);
	margin: 0 auto;
	font-size: 40px;
	color: #000000;
	text-align: center;
	font-weight: bold;
	margin-bottom: 25px;
}
.xz-nr-y{
	width: calc(100% - 46px);
	margin: 0 auto;
	font-size: 30px;
	color: #545968;
	text-align: center;
	line-height: 50px;
	margin-bottom: 50px;
}
.bg-y{
	width: 100%;
	height: 500px;
	box-sizing: border-box;
	padding-top: 40px;
	background-image: url(../assets/img/bg.png);
	background-repeat: no-repeat;
	background-size: cover;
	background-position: top left;
}
.lxwman{
	width: 106px;
	height: 44px;
	background: #ffffff;
	box-sizing: border-box;
	border: 1px solid #dcdfe6;
	border-radius: 6px;
	font-size: 20px;
	color: #606266;
	text-align: center;
	position: absolute;
	right: 23px;
	top: 28px;
	display: flex;
	justify-content: center;
	align-items: center;
}
.logo{
	position: absolute;
	display: block;
	height: 62px;
	left: 23px;
	top: 16px;
}
.hade{
	width: 100%;
	height: 100px;
	box-sizing: border-box;
	background: #FFFFFF;
	border-bottom: 1px solid #f5f5f5;
	position: relative;
}
	
.xz-hand-logo-yd{
	height: 54px;
	display: block;
	position: absolute;
	left: 30px;
	top: 13px;
}
.xz-hand-yd{
	width: 100%;
	border-bottom: 1px solid #ddd;
	height: 80px;
	background: #FFFFFF;
	position: relative;
}
.foot{
	width: 100%;
	color: #68747f;
	line-height: 25PX;
	text-align: center;
	font-size: 13PX;
	padding-top: 20PX;
	padding-bottom: 20PX;
	margin-top: 190PX;
}
.foot a{
	color: #68747f!important;
	cursor: pointer;
}
.go-home{
	position: absolute;
	right: 60PX;
	top: 15PX;
}
.xz-hand-logo{
	height: 48PX;
	display: block;
	position: absolute;
	left: 60PX;
	top: 10PX;
}
.xz-hand-pc{
	width: 100%;
	border-bottom: 1PX solid #ddd;
	height: 68PX;
	background: #FFFFFF;
	position: relative;
}
.store{
	height: 36PX;
	display: block;
	margin: 0 auto;
	margin-top: 30PX;
}
.xz-ms{
	width: 100%;
	color: #999999;
	font-size: 16PX;
	text-align: center;
	margin-top: 20PX;
}
.upload-bg{
	width: 100%;
	display: block;
	margin: 0 auto;
}
.xz-an{
	display: block;
	margin: 0 auto;
	margin-top: 30PX;
}
.xz-img{
	height: 100PX;
	display: block;
	margin: 0 auto;
}
.xz-nr{
	width: 100%;
	font-size: 18PX;
	text-align: center;
	color: #545968;
	margin-bottom: 30PX;
}
.xz-bt{
	width: 100%;
	font-size: 30PX;
	text-align: center;
	color: #000000;
	margin-bottom: 15PX;
}
/* .xz-list{
	width: calc(33.33% - 40PX);
	height: 300PX;
	background: #f5f9fa;
	float: left;
	box-sizing: border-box;
	padding: 40PX 30PX 40PX 30PX;
	margin-right: 60PX;
} */
.xz-list{
	width: calc(33.33% - 40PX);
	height: 300PX;
	background: #f5f9fa;
	margin: 0 auto;
	box-sizing: border-box;
	padding: 40PX 30PX 40PX 30PX;
}
.xz-list1{
	margin-right: 0;
}
.xz-list-bg{
	width: 100%;
	overflow: hidden;
	margin-top: 110PX;
}
.xz-nr{
	width: 100%;
	font-size: 18PX;
	text-align: center;
	color: #545968;
	margin-bottom: 30PX;
}
.xz-bt{
	width: 100%;
	font-size: 30PX;
	text-align: center;
	color: #000000;
	margin-bottom: 15PX;
	font-weight: bold;
	margin-top: 50PX;
}
.bg{
	width: 100%;
	box-sizing: border-box;
	padding-top: 30PX;
	padding-left: 60PX;
	padding-right: 60PX;
	min-width: 1200PX;
	overflow-x: auto;
	background-image: url(../assets/img/bg.png);
	background-repeat: no-repeat;
	background-size: cover;
	background-position: top left;
	height: calc(100% - 70PX);
}
.page{
	width: 100%;
	height: 100VH;
	overflow-y: auto;
}
</style>

<script>
import wxapi from './yd/wxapi.js';
export default {
	name: 'download',
	data () {
		return {
			wx:'',
			ts:false,
			equipment:'',
			wurl:'',
			wname:'',
			aurl:'',
			aname:''
		}
	},
	mounted () {
		let that = this;
		if (that._isMobile()) {
		    that.equipment = 'y';
		} else {
			that.equipment = 'p';
		}
		let broser = window.navigator.userAgent.toLowerCase();
		if(broser.match(/MicroMessenger/i) == 'micromessenger'){
		    that.wx = "wx";
		}
		this.$http('/getdownload').then(function (res) {
			that.aurl = res.data.data.a[0].url;
			that.aname = res.data.data.a[0].name;
			that.wurl = res.data.data.w[0].url;
			that.wname = res.data.data.w[0].name;
		}).catch(function (error) {
		 	
		});
		let url = encodeURIComponent(location.href.split('#')[0]);
		let dz = window.location.href;
		this.$http('/wxjsapi?url=' + url).then(function (response) {
			let data = response.data;
			let option = {
				title: "榕树万千李金鹏",
				desc: "榕树万千客户端下载",
				link: dz,
				imgUrl: "https://www.dmclkj.com/slt.png",
			};
			wxapi.wxRegister(data,option);
		}).catch(function (error) {
			
		});
	},
	methods: {
		_isMobile() {
			let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i);
			return flag;
		},
		gohpme(){
			this.$router.push({
				path: '/index'
			})
		}
	}
};
</script>